import { Controller } from "@hotwired/stimulus"
import {trackEvent} from "../src/analytics";

// Connects to data-controller="analytics-event"
export default class extends Controller {
  static values = {
    name: String,
    value: Number
  }
  connect() {
    trackEvent(this.nameValue, this.valueValue)
    this.element.remove()
  }
}
