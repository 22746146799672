import { Controller } from "@hotwired/stimulus"
import { leave } from 'el-transition'

// Connects to data-controller="self-destruct"
export default class extends Controller {
    static values = {
        delay: { type: Number, default: 3000 }
    }

    connect() {
        setTimeout(async () => {
            await leave(this.element)
            this.element.remove()
        }, this.delayValue)
    }
}
