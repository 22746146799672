import { post } from "@rails/request.js"

/**
 * Get the current notification permission.
 *
 * This does not use `navigation.permissions.query` since that will always be "prompt" on iOS.
 *
 * @returns {Promise<"default"|"denied"|"granted">}
 */
export async function checkStatus() {
  const permission = window.Notification?.permission
  console.log(`Notification Permissions: ${permission}`)
  return permission
}

export async function requestPermission() {
  const permission = await window.Notification.requestPermission();
  return permission;
}

export async function sendSubscriptionToServer() {
  let registration = await navigator.serviceWorker.ready
  const subscription =
      await registration.pushManager.getSubscription() ||
      await reqquestPushNotificationSubscription()

  if (subscription) {
    await post("/web_push_subscriptions", {
      body: JSON.stringify({...subscription.toJSON(), ...{user_agent: navigator.userAgent}})
    })
  } else {
    console.log("Did not complete push subscription")
  }
}

export async function reqquestPushNotificationSubscription() {
  let vapid = new Uint8Array(JSON.parse(document.querySelector("meta[name=webpush_public]")?.content))
  let registration = await navigator.serviceWorker.ready

  try {
    return await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: vapid
    })
  } catch(e) {
    console.log("Denied notifications", e)
    return null
  }
}

export async function setBadgeCount(count) {
  if (!("setAppBadge" in navigator)) return;
  // check for permissions first

  try {
    await navigator.setAppBadge(count);
  } catch (error) {
    console.error("Failed to set app badge", error);
  }
}

export async function clearBadgeCount() {
  if (!("clearAppBadge" in navigator)) return;

  try {
    await navigator.clearAppBadge();
  } catch (error) {
    console.error("Failed to clear app badge", error);
  }
}
