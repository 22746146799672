import {isSuperAdmin} from "./page_properties";

document.addEventListener("turbo:load", function() {
    if (!window.fathom) return

    setTracking()

    window.fathom.trackPageview()
})

function setTracking() {
    if (window.fathom.isTrackingEnabled() && isSuperAdmin()) {
        window.fathom.blockTrackingForMe()
    }
}

export function trackEvent(name, value) {
    window?.fathom?.trackEvent(name, {
        _value: value
    })
}
