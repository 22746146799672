import { Application } from "@hotwired/stimulus"
import { isLocal } from "../src/util"

const application = Application.start()

// Configure Stimulus development experience
application.debug = isLocal()
window.Stimulus   = application

import { Dropdown, Tabs, Toggle } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('tabs', Tabs)
application.register('toggle', Toggle)

import PushUIController from "native_kit/push_ui_controller"
application.register("push-ui", PushUIController)

import Clipboard from 'stimulus-clipboard'
application.register('clipboard', Clipboard)

export { application }
