import { Controller } from "@hotwired/stimulus";
import { enter, leave, toggle } from 'el-transition'

export default class extends Controller {
    static targets = ["target"]
    static values = {
        autoRemove: { type: Boolean, default: true },
        autoRun: { type: Boolean, default: true }
    }
    connect() {
        if (this.autoRunValue) {
            this.enter()
        }
    }

    enter() {
        this.targetTargets.forEach(target => enter(target))
    }

    leave() {
        Promise
            .all(this.targetTargets.map(target => leave(target)))
            .then(() => {
                if (this.autoRemoveValue) {
                    this.element.remove()
                }
            })
    }
    toggle() {
        Promise
            .all(this.targetTargets.map(target => toggle(target)))
            .then(() => {
                // if (this.autoRemoveValue) {
                //     this.element.remove()
                // }
            })
    }

    get visible() {
        return !this.hidden
    }

    get hidden() {
        return this.target.classList.contains('hidden')
    }
}