import { Controller } from "@hotwired/stimulus";
import * as PANotifications from "../src/notifications";

// Connects to data-controller="app-badge"
export default class extends Controller {
    static values = {
        count: Number
    }
    connect() {
        PANotifications.setBadgeCount(this.countValue)
        console.log(`Setting app badge count to ${this.countValue}`)
    }
}