import { Controller } from "@hotwired/stimulus";
import * as PANotifications from "../src/notifications";
import {sendSubscriptionToServer} from "../src/notifications";

// Connects to data-controller="emoji-input"
export default class extends Controller {
  static values = {
    showDenied: {type: Boolean, default: false}
  }
  connect() {
    this.updateUI()
  }

  async updateUI() {
    switch (await PANotifications.checkStatus()) {
      case "granted":
        this.hideButton()
        await PANotifications.sendSubscriptionToServer()
        break
      case "denied":
        if (!this.showDeniedValue) {
          debugger;
          this.hideButton()
        }
        break
      default:
        this.showButton()
        break
    }
  }

  showButton() {
    this.element.classList.add("block")
    this.element.classList.remove("hidden")
  }

  hideButton() {
    this.element.classList.remove("block")
    this.element.classList.add("hidden")
  }

  async request() {
    await PANotifications.requestPermission()
    this.hideButton()
    await PANotifications.sendSubscriptionToServer()
  }
}
