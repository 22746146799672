// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
import 'emoji-picker-element'
import "./src/analytics"
import LocalTime from "local-time"
import {register_service_worker} from "./src/util"


import ahoy from "ahoy.js"
document.addEventListener('turbo:load', () => {
  ahoy.trackView()

  document.body.classList.remove('no-js')
  document.body.classList.add('js')
})

LocalTime.start()
register_service_worker()