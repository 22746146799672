const environment = document.querySelector('body').dataset.railsEnv

export async function register_service_worker() {
  let path = "/service_worker.js" // document.querySelector("meta[name=service_worker]")?.content
  if (!path || !('serviceWorker' in navigator)) return

  try {
    let worker = await navigator.serviceWorker.register(path)
    worker.update() // should I need this every time?
    console.log("service worker registered", worker)
  } catch (e) {
    console.error("Service worker failure", e)
  }
}

export function railsEnv() {
  return environment
}

export function isTest() {
  return railsEnv() == 'testing'
}

export function isDev() {
  return railsEnv() == 'development'
}

export function isLocal() {
  return isTest() || isDev()
}

export function isProd() {
  return railsEnv() == 'production'
}
