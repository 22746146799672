import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="emoji-input"
export default class extends Controller {
  static targets = ["input"]

  initialize() {
    this.emojiPicked = this.emojiPicked.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }

  open() {
    this._showPicker()
    this._pickerContainer.addEventListener('click', this.close)
    this._picker.addEventListener('emoji-click', this.emojiPicked)
  }

  close(event) {
    if (event && event.target != this._pickerContainer) return

    this._hidePicker()
    this._pickerContainer.removeEventListener('click', this.close)
    this._picker.removeEventListener('emoji-click', this.emojiPicked)
  }

  emojiPicked(pick) {
    this.close()
    this.inputTarget.value = pick.detail.emoji.unicode
    

    this.inputTarget.form.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    )
  }

  // private

  get _pickerContainer() {
    return document.getElementById('emoji-picker')
  }

  get _picker() {
    return this._pickerContainer.querySelector('emoji-picker')
  }

  _showPicker() {
    this._pickerContainer.classList.remove('hidden')
  }

  _hidePicker() {
    this._pickerContainer.classList.add('hidden')
  }
}
