import { Autosave } from "tailwindcss-stimulus-components"

export default class extends Autosave {
  static values = {
    duration: { type: Number, default: 1000 }
  }

  connect() {
    this.timeout = null
  }

  save() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = 'Saving...'
      this.formTarget.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      )
    }, this.durationValue)
  }
}