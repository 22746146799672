import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['source', 'filterable']

    filterableTargetConnected(element) {
        if (!this.hasSourceTarget) return

        let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()
        let filterableKey = element.getAttribute('data-filterable-key')
        element.classList.toggle('hidden', !filterableKey.includes(lowerCaseFilterTerm))
    }

    filter(event) {
        if (!this.hasSourceTarget) return

        let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()

        this.filterableTargets.forEach((el, i) => {
            let filterableKey = el.getAttribute('data-filterable-key')

            el.classList.toggle('hidden', !filterableKey.includes(lowerCaseFilterTerm))
        })
    }
}