import Sortable from 'stimulus-sortable'

// Connects to data-controller="sortable"
export default class extends Sortable {
    get defaultOptions() {
        return {
            animation: 250,
            delay: 200,
            delayOnTouchOnly: true
        }
    }
}
