import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-toggle"
export default class extends Controller {
  static targets = ['toggleable', 'data']
  static values = {
    data: { type: Object, default: {} }
  }

  dataValueChanged() {
    this.toggleableTargets.forEach(x => this.setVisibility(x))
  }

  dataTargetConnected(element) {
    const path = element.dataset.userTogglePath
    const value = JSON.parse(element.dataset.userToggleDataValue)
    this.dataValue[path] = value
    element.remove()

    this.toggleableTargets.forEach(x => this.setVisibility(x))
  }

  toggleableTargetConnected(element) {
    this.setVisibility(element)
  }

  setVisibility(element) {
    const path = element.dataset.userTogglePath
    const showIf = Array.of(JSON.parse(element.dataset.userToggleShowIf || null)).flat().filter(Number)
    const showUnless = Array.of(JSON.parse(element.dataset.userToggleShowUnless || null)).flat().filter(Number)
    if (!this.dataValue[path]) return

    const show = (showIf.length && this.dataValue[path].some(x => showIf.includes(x))) ||
      (showUnless.length && !this.dataValue[path].some(x => showUnless.includes(x)))

    element.classList.toggle('hidden', !show)
  }
}
